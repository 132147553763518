import { useParams } from "react-router-dom";
import MusicBox from "../common/MusicBox";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useEffect, useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";

function ProfileMusic() {
  const { username } = useParams();
  const users = useUsers(db);

  const [userSelected, setUserSelected] = useState(null);
  const [music, setMusic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const selectedUser = users.find((user) => user.username === username);
    setUserSelected(selectedUser);
  }, [users, username]);

  useEffect(() => {
    let unsubscribe;

    if (userSelected && userSelected.id) {
      try {
        const userId = userSelected.id;
        console.log("ID del usuario: ", userId);

        const ref = doc(db, "users", userId);
        const collectionRef = collection(ref, "music");

        unsubscribe = onSnapshot(
          collectionRef,
          (querySnapshot) => {
            const musicData = querySnapshot.docs
              .map((doc) => {
                const data = doc.data();
                if (data) {
                  console.log("Documento de música obtenido:", data);
                  return { ...data, id: doc.id };
                } else {
                  console.warn(`El documento con ID ${doc.id} no tiene datos.`);
                  return null;
                }
              })
              .filter((doc) => doc !== null);

            console.log("Datos de música obtenidos: ", musicData);
            setMusic(musicData);
            setLoading(false); // Actualizar el estado de carga aquí
          },
          (error) => {
            console.error("Error al obtener la colección de música: ", error);
            setError(error.message);
            setLoading(false); // Actualizar el estado de carga en caso de error
          }
        );
      } catch (error) {
        console.error("Error al obtener la colección de música: ", error);
        setError(error.message);
        setLoading(false); // Actualizar el estado de carga en caso de error
      }
    } else {
      console.warn("Usuario no seleccionado, ID no definido.");
      setLoading(false); // Actualizar el estado de carga si no hay usuario seleccionado
    }

    return () => {
      if (unsubscribe) unsubscribe(); // Desuscribirse cuando el componente se desmonta
    };
  }, [userSelected]);

  if (loading) {
    return <div className="code">Cargando...</div>;
  }

  if (error) {
    return <div className="code">Error: {error}</div>;
  }

  if (!music.length) {
    return <div className="code">No se encontró música...</div>;
  }

  console.log("Lanzamientos disponibles: ", music);

  return (
    <>
      {userSelected ? (
        <div className="profile-music">
          {music && (
            <>
              {Array.isArray(music) && music.length > 0 ? (
                <>
                  {music
                    .sort((a, b) => {
                      if (a.date && b.date) {
                        return (
                          new Date(b.timestamp.seconds * 1000) -
                          new Date(a.timestamp.seconds * 1000)
                        );
                      } else {
                        return 0; // No hay fecha para comparar, conservar el orden original
                      }
                    })
                    .map((musicItem, index) => (
                      <MusicBox
                        key={index}
                        {...musicItem}
                        name={userSelected.name}
                      />
                    ))}
                </>
              ) : (
                <div className="code">No se encontró música...</div>
              )}
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default ProfileMusic;
