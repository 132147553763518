import { useParams } from "react-router-dom";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useEffect, useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { faImages } from "@fortawesome/free-regular-svg-icons";

function ProfilePhotos() {
  const { username } = useParams();
  const users = useUsers(db);

  const [userSelected, setUserSelected] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [glass, setGlass] = useState(false);
  const [photoSelected, setPhotoSelected] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const changePhoto = (photo) => {
    setPhotoSelected(photo);
    setGlass(true);
    setCurrentIndex(0);
  };

  const removePhoto = () => {
    setPhotoSelected(null);
    setGlass(false);
  };

  useEffect(() => {
    const selectedUser = users.find((user) => user.username === username);
    setUserSelected(selectedUser);
  }, [users, username]);

  useEffect(() => {
    let unsubscribe;

    if (userSelected && userSelected.id) {
      try {
        const userId = userSelected.id;
        console.log("ID del usuario: ", userId);

        const ref = doc(db, "users", userId);
        const collectionRef = collection(ref, "photos");

        unsubscribe = onSnapshot(
          collectionRef,
          (querySnapshot) => {
            const photoData = querySnapshot.docs
              .map((doc) => {
                const data = doc.data();
                if (data) {
                  console.log("Documento de fotos obtenido:", data);
                  return { ...data, id: doc.id };
                } else {
                  console.warn(`El documento con ID ${doc.id} no tiene datos.`);
                  return null;
                }
              })
              .filter((doc) => doc !== null);

            console.log("Datos de música obtenidos: ", photoData);
            setPhotos(photoData);
            setLoading(false); // Actualizar el estado de carga aquí
          },
          (error) => {
            console.error("Error al obtener la colección de música: ", error);
            setError(error.message);
            setLoading(false); // Actualizar el estado de carga en caso de error
          }
        );
      } catch (error) {
        console.error("Error al obtener la colección de fotos: ", error);
        setError(error.message);
        setLoading(false); // Actualizar el estado de carga en caso de error
      }
    } else {
      console.warn("Usuario no seleccionado, ID no definido.");
      setLoading(false); // Actualizar el estado de carga si no hay usuario seleccionado
    }

    return () => {
      if (unsubscribe) unsubscribe(); // Desuscribirse cuando el componente se desmonta
    };
  }, [userSelected]);

  if (loading) {
    return <div className="code">Cargando...</div>;
  }

  if (error) {
    return <div className="code">Error: {error}</div>;
  }

  if (!photos.length) {
    return <div className="code">No se encontrarón fotos...</div>;
  }

  console.log("Fotos disponibles: ", photos);

  const nextPhoto = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % photoSelected.photos.length
    );
  };

  const prevPhoto = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + photoSelected.photos.length) %
        photoSelected.photos.length
    );
  };

  return (
    <>
      {userSelected ? (
        <div className="profile-photos">
          {photos && (
            <>
              {Array.isArray(photos) && photos.length > 0 ? (
                <>
                  {photos
                    .sort((a, b) => {
                      if (a.timestamp && b.timestamp) {
                        return (
                          new Date(b.timestamp.seconds * 1000) -
                          new Date(a.timestamp.seconds * 1000)
                        );
                      } else {
                        return 0; // No hay fecha para comparar, conservar el orden original
                      }
                    })
                    .map((photo, index) => (
                      <div
                        key={index}
                        className="photo-box"
                        onClick={() => changePhoto(photo)}
                      >
                        <img
                          src={photo.photos} // [0].url
                          alt={photo.description}
                          loading="lazy"
                        />
                        {photo.photos.length > 1 && (
                          <FontAwesomeIcon icon={faImages} size="xl" />
                        )}
                      </div>
                    ))}
                </>
              ) : (
                <p>No photo available</p>
              )}
            </>
          )}
        </div>
      ) : null}
      <div
        className={!glass ? "photo-page" : "photo-page show"}
        onClick={() => removePhoto()}
      ></div>
      {photoSelected && (
        <div className="profile-post">
          <button
            type="button"
            className="button close"
            onClick={() => removePhoto()}
          >
            <FontAwesomeIcon icon={faClose} size="2xl" />
          </button>
          <div className="profile-post-photos">
            {photoSelected.photos.length > 1 && (
              <>
                <button onClick={prevPhoto} className="prev-button">
                  <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                </button>
                <button onClick={nextPhoto} className="next-button">
                  <FontAwesomeIcon icon={faAngleRight} size="xl" />
                </button>
              </>
            )}
            <img
              src={photoSelected.photos[currentIndex]} // [0].url
              alt={photoSelected.description}
              className="profile-post-photo-back"
              loading="lazy"
            />
            <div className="photo-item">
              <img
                src={photoSelected.photos[currentIndex]} // [0].url
                alt={photoSelected.description}
                className="profile-post-photo"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePhotos;
