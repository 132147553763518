import "./assets/style/app.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./firebase/fbQueries";
import { db } from "./firebase/config";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";
import Layout from "./components/common/Layout";
import Home from "./components/pages/Home";
import Explore from "./components/pages/Explore";
import Watch from "./components/pages/Watch";
import Compose from "./components/pages/Compose";
import Profile from "./components/pages/Profile";
import ProfileHome from "./components/pages/ProfileHome";
import ProfileMusic from "./components/pages/ProfileMusic";
import ProfilePhotos from "./components/pages/ProfilePhotos";
import ProfileVideos from "./components/pages/ProfileVideos";
import ProfileStore from "./components/pages/ProfileStore";
import ProfileAbout from "./components/pages/ProfileAbout";
import ProfileLoved from "./components/pages/ProfileLoved";
import ProfileSaved from "./components/pages/ProfileSaved";
import ProfileArchive from "./components/pages/ProfileArchive";
import ProfileEdit from "./components/pages/ProfileEdit";
import ProfileStats from "./components/pages/ProfileStats";

function App() {
  const authUser = useAuth(db);

  return (
    <Routes>
      {authUser && (
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/watch" element={<Watch />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/create" element={<Compose />} />
          <Route element={<Profile />}>
            <Route path="/:username" element={<ProfileHome />} />
            <Route path="/:username/music" element={<ProfileMusic />} />
            <Route path="/:username/photos" element={<ProfilePhotos />} />
            <Route path="/:username/members" element={<ProfileVideos />} />
            <Route path="/:username/store" element={<ProfileStore />} />
            <Route path="/:username/about" element={<ProfileAbout />} />
            <Route path="/:username/loved" element={<ProfileLoved />} />
            <Route path="/:username/saved" element={<ProfileSaved />} />
            <Route path="/:username/*" element={"No Found"} />
          </Route>
          <Route path="/:username/edit" element={<ProfileEdit />} />
          <Route path="/:username/archive" element={<ProfileArchive />} />
          <Route path="/:username/stats" element={<ProfileStats />} />
          <Route path="*" element={<Home />} />
          <Route path="/login" element={<Navigate to={"/"} replace />} />
          <Route path="/register" element={<Navigate to={"/"} replace />} />
        </Route>
      )}
      {!authUser && (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Navigate to={"/login"} replace />} />
        </>
      )}
    </Routes>
  );
}

export default App;
