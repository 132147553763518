import { Link } from "react-router-dom";

function AboutApp() {
  return (
    <div className="about-app">
        <Link to="">Nosotros</Link>
        <Link to="">Política de Cookies</Link>
        <Link to="">Política de Anuncios</Link>
        <Link to="">Términos & Condiciones</Link>
        <Link to="">Preferencias de Privacidad</Link>
    </div>
  )
}

export default AboutApp;