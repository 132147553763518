import watch from "../../database/Watch"
import WatchCard from "../common/WatchCard"

function Watch() {
  return (
    <div className="watch">
        <div className="watch-grid">
            {watch.map((item) => (
                <WatchCard 
                    key={item.id}
                    path={item.path}
                    video={item.video}
                    poster={item.poster}
                    title={item.title}
                    avatar={item.avatar}
                    name={item.name}
                    username={item.username}
                />
            ))}
        </div>
    </div>
  )
}

export default Watch