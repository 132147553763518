import ContentPhoto from "./ContentPhoto";
import PostAction from "./PostAction";
import PostHead from "./PostHead";

function PostPhoto( props ) {
  return (
    <div className="post">
      <PostHead 
        name={props.name}
        picture={props.picture}
        verified={props.verified}
        username={props.username}
        followers={props.followers}
      />
      <ContentPhoto 
        username={props.username}
        gallery={props.gallery}
        text={props.text}
        date={props.date}
      />
      <PostAction />
    </div>
  )
}

export default PostPhoto;