import { useState } from "react";
import Glass from "./Glass";
import Header from "./Header";
import Main from "./Main";
import ScrollToTop from "./ScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faMusic } from "@fortawesome/free-solid-svg-icons";
import Compose from "../pages/Compose";
import ComposeMusic from "../pages/ComposeMusic";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Layout() {
  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  const [glass, setGlass] = useState(false);
  const [compose, setCompose] = useState(false);
  const [composeMusic, setComposeMusic] = useState(false);

  const handleGlass = () => {
    setGlass(false);
    setCompose(false);
    setComposeMusic(false);
  };

  const newMusic = () => {
    setGlass(true);
    setComposeMusic(true);
  };

  const newPost = () => {
    setGlass(true);
    setCompose(true);
  };

  return (
    <div className={"app"}>
      <ScrollToTop />
      <Header />
      <button
        type="button"
        className="button create-button"
        title="Crear una publicación"
        aria-label="Crear una publicación"
        onClick={() => newPost()}
      >
        <FontAwesomeIcon icon={faAdd} size="2xl" />
      </button>
      {userSelected ? (
        <>
          {userSelected.show_music && (
            <button
              type="button"
              className="button create-music-button"
              onClick={() => newMusic()}
            >
              <FontAwesomeIcon icon={faMusic} size="2xl" />
            </button>
          )}
        </>
      ) : null}
      <ComposeMusic
        composeMusic={composeMusic}
        setComposeMusic={setComposeMusic}
        setGlass={setGlass}
      />
      <Compose compose={compose} setCompose={setCompose} setGlass={setGlass} />
      <Main />
      <Glass glass={glass} handleGlass={handleGlass} />
    </div>
  );
}

export default Layout;
