import { useState } from "react";
import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import UserCard from "../common/UserCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Explore() {
  const users = useUsers(db);
  const [searchTerm, setSearchTerm] = useState("");

  // Función para manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar usuarios por nombre o nombre de usuario
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>Explorar ~ {website_name}</title>
      </Helmet>
      <div className="explore page">
        <div className="row">
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Buscar por nombre o nombres de usuarios."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </div>
        </div>
        <div className="column">
          {filteredUsers.map((user, index) => (
            <UserCard key={index} {...user} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Explore;

