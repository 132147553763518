import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as iconLove,
  faComment as iconComment,
  faBookmark as iconSave,
} from "@fortawesome/free-regular-svg-icons";
import {
  faHeart as iconLoved,
  faComment as iconCommented,
  faShare,
  faBookmark as iconSaved,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";

function PostAction(props) {
  // love
  const [love, setLove] = useState(false);
  const [countLove, setCountLove] = useState(0);

  const giveLove = () => {
    setLove(!love);
    if (love === false) {
      setCountLove(countLove + 1);
    } else {
      setCountLove(countLove - 1);
    }
  };

  // comment
  const [comment, setComment] = useState(false);
  const [countComment, setCountComment] = useState(0);

  const commentPost = () => {
    setComment(!comment);
    if (comment === false) {
      setCountComment(countComment + 1);
    } else {
      setCountComment(countComment - 1);
    }
  };

  // share
  const [share, setShare] = useState(false);
  const [countShare, setCountShare] = useState(0);

  const sharePost = () => {
    setShare(!share);
    if (share === false) {
      setCountShare(countShare + 1);
    } else {
      setCountShare(countShare - 1);
    }
  };

  // save
  const [save, setSave] = useState(false);
  const [countSave, setCountSave] = useState(0);

  const savePost = () => {
    setSave(!save);
    if (save === false) {
      setCountSave(countSave + 1);
    } else {
      setCountSave(countSave - 1);
    }
  };

  return (
    <div className="post-action">
      <div className="flex start">
        <div className="reaction-area">
          <button
            type="button"
            className={!love ? "react-button" : "react-button show"}
            onClick={() => giveLove()}
          >
            <FontAwesomeIcon icon={!love ? iconLove : iconLoved} size="2xl" />
          </button>
          <div className={!love ? "react-count" : "react-count show"}>
            {countLove}
          </div>
        </div>
        <div className="reaction-area">
          <button
            type="button"
            className="react-button"
            onClick={() => commentPost()}
          >
            <FontAwesomeIcon
              icon={!comment ? iconComment : iconCommented}
              size="2xl"
            />
          </button>
          <div className="react-count">{countComment}</div>
        </div>
        <div className="reaction-area">
          <button
            type="button"
            className="react-button"
            onClick={() => sharePost()}
          >
            <FontAwesomeIcon icon={faRetweet} size="2xl" />
          </button>
          <div className="react-count">{countShare}</div>
        </div>
        <div className="reaction-area" role="button">
          <div
            type="button"
            className="react-button"
            onClick={() => sharePost()}
          >
            <FontAwesomeIcon icon={faShare} size="2xl" />
          </div>
          <div className="react-count">{countShare}</div>
        </div>
      </div>
      <div className="flex end">
        <div className="reaction-area">
          <button
            type="button"
            className={!save ? "react-button" : "react-button show"}
            onClick={() => savePost()}
          >
            <FontAwesomeIcon icon={!save ? iconSave : iconSaved} size="2xl" />
          </button>
          {/* <div className="react-count">{countSave}</div> */}
        </div>
      </div>
    </div>
  );
}

export default PostAction;
