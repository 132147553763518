import { useState } from "react";
import { useParams } from "react-router-dom";

import ProfileHeader from "../common/ProfileHeader";
import ProfileContent from "../common/ProfileContent";
import { Helmet } from "react-helmet";
// import ProfileAbout from "../common/ProfileAbout";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { collection, updateDoc } from "firebase/firestore";

function Profile() {
  // const navigate = useNavigate();
  const { username } = useParams();
  const users = useUsers(db);
  const userSelected = users.find((user) => user.username === username);

  // const [about, setAbout] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  // followers
  const [followers, SetFollowers] = useState(0);
  const [follow, SetFollow] = useState(false);
  const [notify, setNotify] = useState(false);

  const followed = async (id) => {
    SetFollow(!follow);
    if (follow === false) {
      SetFollowers(followers + 1);
    } else {
      SetFollowers(followers - 1);
      setNotify(false);
    }

    const userRef = collection(db, "users", userSelected.id);

    try {
      await updateDoc(userRef, {
        followers: followers,
      });
    } catch (error) {
      console.error("Error al actualizar el contador de seguidores: ", error);
    }
  };

  const activeNotify = () => {
    setNotify(!notify);
  };

  return (
    <>
      {userSelected ? (
        <>
          <Helmet>
            <title>
              {userSelected.name +
                " (@" +
                userSelected.username +
                ") ~ Compose"}
            </title>
          </Helmet>
          <div className="profile page">
            <ProfileHeader
              picture={userSelected.picture}
              name={userSelected.name}
              verified={userSelected.verified}
              username={userSelected.username}
              label={userSelected.label}
              description={userSelected.description}
              website={userSelected.website}
              followers={userSelected.followers}
              following={userSelected.following}
              follow={follow}
              notify={notify}
              clickFollow={followed}
              activeNotify={activeNotify}
              handleEdit={handleEdit}
              show_music={userSelected.show_music}
              show_members={userSelected.show_members}
              show_store={userSelected.show_store}
              show_stats={userSelected.show_stats}
            />
            <ProfileContent />
          </div>
        </>
      ) : null}
    </>
  );
}

export default Profile;
