import { db } from "../../firebase/config";
import { useUsers } from "../../firebase/fbQueries";
import UserCard from "./UserCard";

function Popular() {
  const users = useUsers(db);

  if (!users || users.length === 0) {
    return <div>Loading...</div>;
  }

  console.log("All Users:", users);

  // Filtrar usuarios verificados o con más de 1000 seguidores
  const verifiedOrPopular = users.filter(
    (user) => user.verified || user.followers >= 1000
  );

  console.log("Filtered Users:", verifiedOrPopular);

  // Ordenar por cantidad de seguidores en orden descendente
  const sortedUsers = verifiedOrPopular.sort((a, b) => b.followers - a.followers);

  console.log("Sorted Users:", sortedUsers);

  // Seleccionar los primeros 6 usuarios
  const topUsers = sortedUsers.slice(0, 6);

  return (
    <div className="popular">
      {topUsers.map((user) => (
        <UserCard key={user.id} {...user} />
      ))}
    </div>
  );
}

export default Popular;

