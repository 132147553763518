import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase/config";
import { useTranslation } from "react-i18next";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function UserCard(props) {
  const navigate = useNavigate();

  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  const { t } = useTranslation();

  const [follow, setFollow] = useState(false);

  const followed = () => {
    setFollow(!follow);
  };

  const ViewProfile = () => {
    navigate(`/${userSelected.username}`);
  };

  function abbreviateFollowers(followerCount) {
    if (typeof followerCount !== "number" || isNaN(followerCount)) {
      return "Invalid input";
    }

    const units = ["", " K", " M", " B", " T", " P", " E", " Z", " Y", " GOL"];
    let unitIndex = 0;

    while (followerCount >= 1000 && unitIndex < units.length - 1) {
      followerCount /= 1000;
      unitIndex++;
    }

    return followerCount.toFixed(1).replace(/\.0$/, '') + units[unitIndex];
  }

  const followers = abbreviateFollowers(props.followers);

  return (
    <>
      {props.username && (
        <div className="user-card">
          <div className="user-info">
            <Link to={`/${props.username}`} className="no-link">
              {props.picture ? (
                <img
                  src={props.picture}
                  alt={
                    "Foto de perfil de " + props.name + ` (@${props.username})`
                  }
                  className="user-picture"
                />
              ) : (
                <div className="user-picture">
                  <FontAwesomeIcon icon={faUser} size="2xl" />
                </div>
              )}
            </Link>
            <Link to={`/${props.username}`} className="no-link">
              <div className="user-data">
                <div className="user-details">
                  <div className="user-name-brand">
                    <span className="name-brand">{props.name}</span>
                    {props.verified && (
                      <span className="verified">
                        <svg
                          aria-label="Verificado"
                          className="x1lliihq x1n2onr6"
                          fill="rgb(0, 149, 246)"
                          height="18"
                          role="img"
                          viewBox="0 0 40 40"
                          width="18"
                        >
                          <title>Verificado</title>
                          <path
                            d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                        {/* <FontAwesomeIcon icon={faFeather} /> */}
                      </span>
                    )}
                  </div>
                </div>
                <div className="user-details">
                  <div className="user-username">@{props.username}</div>
                  {/* {followers && (
                    )} */}
                  <div className="user-followers">
                    {followers + " Seguidores"}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {userSelected ? (
            <>
              {userSelected.username === props.username ? (
                <>
                  <button
                    className="button followed-button"
                    type="button"
                    onClick={() => ViewProfile()}
                  >
                    Ver perfil
                  </button>
                </>
              ) : (
                <>
                  <button
                    className={
                      !follow
                        ? "button follow-button"
                        : "button followed-button"
                    }
                    type="button"
                    onClick={() => followed()}
                  >
                    {!follow ? t("button.follow") : t("button.followed")}
                  </button>
                </>
              )}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default UserCard;
