import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBP6N1tZhpl-8ifijtp4AmNT4F0czR_CyU",
  authDomain: "the-compose-art.firebaseapp.com",
  projectId: "the-compose-art",
  storageBucket: "the-compose-art.appspot.com",
  messagingSenderId: "203872166374",
  appId: "1:203872166374:web:56d24cd31403364050b627",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export {
  auth,
  db,
  storage,
  analytics,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
};
