import {
  faBookmark,
  faCrown,
  faHeart,
  faHome,
  faImage,
  faInfoCircle,
  faMusic,
  faStore,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProfileMenuStates from "../hooks/ProfileMenuStates";

function ProfileNavMenu(props) {
  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  const {
    home,
    music,
    videos,
    photos,
    members,
    store,
    about,
    loved,
    saved,
    handleHome,
    handleMusic,
    handlePhotos,
    handleVideos,
    handleMembers,
    handleStore,
    handleAbout,
    handleLoved,
    handleSaved,
  } = ProfileMenuStates();

  return (
    <nav className="profile-nav-menu">
      {/* Home */}
      <Link
        to={`/${props.username}`}
        className={
          !home ? "button profile-button" : "button profile-button show"
        }
        onClick={handleHome}
      >
        <FontAwesomeIcon icon={faHome} size="lg" />
        <span className="line"></span>
      </Link>
      {/* Music */}
      {props.show_music && (
        <Link
          to={`/${props.username}/music`}
          className={
            !music ? "button profile-button" : "button profile-button show"
          }
          onClick={handleMusic}
        >
          <FontAwesomeIcon icon={faMusic} size="lg" />
          {/* Música */}
          <span className="line"></span>
        </Link>
      )}
      {/* Photos */}
      <Link
        to={`/${props.username}/photos`}
        className={
          !photos ? "button profile-button" : "button profile-button show"
        }
        onClick={handlePhotos}
      >
        <FontAwesomeIcon icon={faImage} size="lg" />
        <span className="line"></span>
      </Link>
      {/* Videos */}
      {false && (
        <Link
          to={`/${props.username}/videos`}
          className={
            !videos ? "button profile-button" : "button profile-button show"
          }
          onClick={handleVideos}
        >
          <FontAwesomeIcon icon={faVideo} size="lg" />
          <span className="line"></span>
        </Link>
      )}
      {/* Members */}
      {props.show_members && (
        <Link
          to={`/${props.username}/members`}
          className={
            !members ? "button profile-button" : "button profile-button show"
          }
          onClick={handleMembers}
        >
          <FontAwesomeIcon icon={faCrown} size="lg" />
          <span className="line"></span>
        </Link>
      )}
      {props.show_store && (
        <Link
          to={`/${props.username}/store`}
          className={
            !store ? "button profile-button" : "button profile-button show"
          }
          onClick={handleStore}
        >
          <FontAwesomeIcon icon={faStore} size="lg" />
          <span className="line"></span>
        </Link>
      )}
      <Link
          to={`/${props.username}/about`}
          className={
            !about ? "button profile-button" : "button profile-button show"
          }
          onClick={handleAbout}
        >
          <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          <span className="line"></span>
        </Link>
      {userSelected &&
        (userSelected.username === props.username ? (
          <>
            <Link
              to={`/${props.username}/loved`}
              className={
                !loved ? "button profile-button" : "button profile-button show"
              }
              onClick={handleLoved}
            >
              <FontAwesomeIcon icon={faHeart} size="lg" />
              <span className="line"></span>
            </Link>
            <Link
              to={`/${props.username}/saved`}
              className={
                !saved ? "button profile-button" : "button profile-button show"
              }
              onClick={handleSaved}
            >
              <FontAwesomeIcon icon={faBookmark} size="lg" />
              <span className="line"></span>
            </Link>
          </>
        ) : null)}
    </nav>
  );
}
export default ProfileNavMenu;
