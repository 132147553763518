import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";

function ContentPhoto(props) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % props.gallery.length);
  };

  const prevPhoto = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + props.gallery.length) % props.gallery.length
    );
  };

  return (
    <div className="post-content">
      <div className="content-photo">
        {/* {props.gallery && (
          <div className="scroll-photos">
            {props.gallery.map((photo, index) => (
              <div className="photo-item" key={index}>
                <img src={photo} alt={photo.description} />
              </div>
            ))}
          </div>
        )} */}
        {props.gallery && props.gallery.length > 0 && (
          <div className="photo-slider">
            {props.gallery.length > 1 && (
              <>
                <button onClick={prevPhoto} className="prev-button">
                  <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                </button>
                <button onClick={nextPhoto} className="next-button">
                  <FontAwesomeIcon icon={faAngleRight} size="xl" />
                </button>
              </>
            )}
            <div className="photo-item">
              <img
                src={props.gallery[currentIndex]}
                alt={props.gallery[currentIndex].description}
              />
            </div>
          </div>
        )}
        <p className="text">
          <span className="username">
            <Link to={`/${props.username}`} className="no-link">
              @{props.username}
            </Link>
          </span>
          {props.date && (
            <span className="date">
              {new Date(props.date.seconds * 1000).toLocaleDateString("es", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </span>
          )}
          {props.text}
        </p>
      </div>
    </div>
  );
}

export default ContentPhoto;
