import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "./config";

export const subscribeToPhotos = (callback) => {
  return onSnapshot(collection(db, "users"), (usersSnapshot) => {
    const allPhotosPromises = usersSnapshot.docs.map(async (userDoc) => {
      const photosSnapshot = await getDocs(collection(db, "users", userDoc.id, "photos"));
      const userData = userDoc.data();

      return photosSnapshot.docs.map(photoDoc => ({
        id: photoDoc.id,
        ...photoDoc.data(),
        user: {
          id: userDoc.id,
          ...userData
        }
      }));
    });

    Promise.all(allPhotosPromises).then(photoArrays => {
      const allPhotos = photoArrays.flat();
      callback(allPhotos);
    });
  });
};