import "../../assets/style/logo.css";
import { Link } from "react-router-dom";

function Logo(props) {

  return (
    <Link to="/" className="logo" onClick={props.click}>Compose</Link>
  )
}

export default Logo;