import { useState } from "react";

import UserCard from "./UserCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faStar as iconAdd } from "@fortawesome/free-regular-svg-icons";
import { faStar as iconAdded } from "@fortawesome/free-solid-svg-icons";


function PostHead(props) {

  const [add, setAdd] = useState(false);

  const follow = () => {
    setAdd(!add);
  };

  return (
    <div className="post-head">
      <UserCard
        name={props.name}
        picture={props.picture}
        verified={props.verified}
        username={props.username}
        followers={props.followers}
      />
      <button
        type="button"
        className="button icon-button"
        onClick={follow}
        title={!add ? "Agregar a Favoritos" : "Eliminar de Favoritos"}
      >
        <FontAwesomeIcon icon={!add ? iconAdd : iconAdded} color={!add ? "white" : "#f02849"} />
      </button>
      <button type="button" className="button icon-button" title="Más opciones">
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
    </div>
  );
}

export default PostHead;
