import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

// ---- ---- authentication ---- ---- //
export function useAuth(db) {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return authUser;
}

// ---- ---- users ---- ---- //
export function useUsers(db) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const ref = collection(db, "users");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setUsers(data);
    });

    return () => unsubscribe();
  }, [db]);

  return users;
}

// ---- ---- music ---- ---- //
export function useMusic(db) {
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const userId = doc.id;
    console.log("USer ID: ", userId);

    const ref = collection(db, "users", doc.id, "music");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMusic(data);
      console.log(data);
    });

    return () => unsubscribe();
  }, [db]);

  return music;
}
