import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faEarthAmerica,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { countryCodes } from "../../config/websiteConfig";

function ProfileAbout(props) {
  const users = useUsers(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);

  if (!userSelected) {
    return <div>Loading...</div>;
  }

  const htmlStringDesc = userSelected.description;
  const countryName = countryCodes[userSelected.country] || countryCodes; // || 'Unknown Country'

  return (
    <>
      {userSelected && (
        <div className="profile-about">
          {userSelected.description && (
            <div className="profile-block">
              <div className="profile-block-title">
                <h2>Descripción</h2>
              </div>
              <div className="profile-block-content">
                <p dangerouslySetInnerHTML={{ __html: htmlStringDesc }}></p>
              </div>
            </div>
          )}
          <div className="profile-block">
            <div className="profile-block-title">
              <h2>Información del perfil</h2>
            </div>
            <div className="profile-block-content">
              {userSelected.since && (
                <div className="info-box">
                  <div className="info-box-icon">
                    <FontAwesomeIcon icon={faCalendarDay} size="xl" />
                  </div>
                  <div className="info-box-data">
                    <span className="info-box-text">
                      Se unió el{" "}
                      {new Date(
                        userSelected.since.seconds * 1000
                      ).toLocaleDateString("es", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              )}
              {countryName && (
                <div className="info-box">
                  <div className="info-box-icon">
                    <FontAwesomeIcon icon={faEarthAmerica} size="xl" />
                  </div>
                  <div className="info-box-data">
                    <span className="info-box-text">{countryName}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileAbout;
