import { useParams } from "react-router-dom";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useEffect, useState } from "react";

function ProfileLoved(props) {
  const { username } = useParams();
  const users = useUsers(db);
  const authUser = useAuth(db);
  const [userSelected, setUserSelected] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authUser && users.length > 0) {
      try {
        const selectedUser = users.find(
          (user) => user.email === authUser.email
        );
        if (selectedUser) {
          setUserSelected(selectedUser);
        } else {
          setError(
            "Usuario autenticado no encontrado en la lista de usuarios."
          );
        }
      } catch (error) {
        setError("Error al obtener el usuario seleccionado.");
      } finally {
        setLoading(false);
      }
    }
  }, [users, authUser]);

  if (loading) {
    return <div className="code">Cargando...</div>;
  }

  if (error) {
    return <div className="code">Error: {error}</div>;
  }

  if (!userSelected) {
    return <div className="code">No se encontró el usuario.</div>;
  }

  if (userSelected.username !== username) {
    return <div className="code">Error: no tienes acceso a esta página.</div>;
  }

  return <div className="code">En esta página aparecerán las publicaciones que te han encantado.</div>;
}

export default ProfileLoved;
