import {
  addDoc,
  // arrayUnion,
  collection,
  doc,
  // getDoc,
  // getDocs,
  serverTimestamp,
  // setDoc,
  // updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase/config";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faImages,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { useRef, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function Compose(props) {
  // const [date, setDate] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [files, setFiles] = useState([]);
  const containerRef = useRef(null);

  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  // const handleDate = () => {
  //   setDate(!date);
  // };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setPhotos(true);
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const uploadFile = async (file) => {
    const userId = userSelected.id;
    const username = userSelected.username;

    console.log("ID del usuario: ", userId);
    console.log("Nombre de usuario: ", username);

    const storageRef = ref(storage, `users/${username}/photos/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);

    // const userDoc = doc(db, "users", userId);
    // const photosCollectionRef = collection(userDoc, "photos");
    // await addDoc(photosCollectionRef, {
    //   photos: [url],
    // });

    // console.log(url)
    return url;
  };

  const onSubmit = async (data) => {
    const { description } = data;

    try {
      const photoURLs = await Promise.all(
        files.map((file) => uploadFile(file))
      );

      console.log(photoURLs);

      const userId = userSelected.id;
      const userDoc = doc(db, "users", userId);
      const photosCollection = collection(userDoc, "photos");

      // Creamos un objeto que contiene la descripción y la fecha para todas las fotos
      const postData = {
        userId: userSelected.id,
        description: description,
        photos: photoURLs,
        timestamp: serverTimestamp(),
      };

      console.log(postData);

      // Agregamos el objeto al documento en Firestore
      await addDoc(photosCollection, postData);

      reset();
      setFiles([]);
      setPhotos(false);
      props.setCompose(false);
      props.setGlass(false);
    } catch (error) {
      console.error("Error al crear la publicación:", error);
    }
  };

  // Handling drag functionality
  const handleMouseDown = (e) => {
    const container = containerRef.current;
    container.classList.add("grabbing");
    container.dataset.isDown = true;
    container.dataset.startX = e.pageX - container.offsetLeft;
    container.dataset.scrollLeft = container.scrollLeft;
  };

  const handleMouseLeave = () => {
    const container = containerRef.current;
    container.classList.remove("grabbing");
    container.dataset.isDown = false;
  };

  const handleMouseUp = () => {
    const container = containerRef.current;
    container.classList.remove("grabbing");
    container.dataset.isDown = false;
  };

  const handleMouseMove = (e) => {
    const container = containerRef.current;
    if (container.dataset.isDown !== "true") return;
    e.preventDefault();
    const x = e.pageX - container.offsetLeft;
    const walk = (x - container.dataset.startX) * 1; // Ajusta la velocidad de desplazamiento
    container.scrollLeft = container.dataset.scrollLeft - walk;
  };

  return (
    <div className={!props.compose ? "compose" : "compose show"}>
      <div className="compose-caption">
        {userSelected && (
          <div className="user-box">
            <div className="user-box-picture">
              {userSelected.picture ? (
                <>
                  <img src={userSelected.picture} alt={userSelected.name} />
                </>
              ) : (
                <>
                  <div className="user-picture">
                    <FontAwesomeIcon icon={faUser} size="xl" />
                  </div>
                </>
              )}
            </div>
            <div className="user-box-data">
              <div className="user-box-name">
                {userSelected.name}
                {userSelected.verified && (
                  <span className="verified-badge" title="Verificado">
                    <svg
                      aria-label="Verificado"
                      className="x1lliihq x1n2onr6"
                      fill="rgb(0, 149, 246)"
                      height="16"
                      role="img"
                      viewBox="0 0 40 40"
                      width="16"
                    >
                      <title>Verificado</title>
                      <path
                        d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                )}
              </div>
              <div className="user-box-username">@{userSelected.username}</div>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          rows={3}
          placeholder="Descripción de la fotografía... (opcional)"
          {...register("description", {
            required: {
              value: false,
              message: "La descripción es requerida*",
            },
            maxLength: {
              value: 180,
              message: "La descripción no puede exceder los 180 caracteres*",
            },
          })}
        ></textarea>
        {errors.description && <span>{errors.description.message}</span>}
        <input
          type="file"
          accept="image/*"
          {...register("photos", { required: "La imagen es requerida*" })}
          onChange={handleFileChange}
          multiple
          hidden
          id="photos"
        />
        {photos && files.length > 0 && (
          <div
            className="selected-images"
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {files.map((file, index) => (
              <div key={index} className="image-preview">
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Selected ${index}`}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveFile(index)}
                  className="remove-button"
                  title="Eliminar esta foto"
                >
                  <FontAwesomeIcon icon={faTrashCan} size="lg" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="compose-action">
          <div className="icons">
            <button
              type="button"
              className="button icon-button"
              title="Adjuntar archivo multimedia"
              aria-label="Adjuntar archivo multimedia"
            >
              <label htmlFor="photos">
                <FontAwesomeIcon icon={faImages} />
              </label>
            </button>
            <button
              type="button"
              className="button icon-button"
              title="Cambiar visibilidad: Publico"
              aria-label="Cambiar visibilidad: Publico"
              style={{ cursor: "no-drop" }}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
          <button type="submit">Publicar</button>
        </div>
      </form>
    </div>
  );
}

export default Compose;
