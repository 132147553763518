import avatar from "../assets/images/published.png";
import v4 from "../assets/videos/Stray - Descansando en el departamento de clementine, en el centro (7).mp4"
import p4 from "../assets/images/Clementine.png";

const watch = [
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (1/12) | Daniel Vargas, Stray - Dentro del muro (1/12) | Daniel Vargas, Stray - Dentro del muro (1/12) | Daniel Vargas",
        path: "video-1",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (2/12) | Daniel Vargas",
        path: "video-2",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (3/12) | Daniel Vargas",
        path: "video-3",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (4/12) | Daniel Vargas",
        path: "video-4",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (1/12) | Daniel Vargas",
        path: "video-1",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (2/12) | Daniel Vargas",
        path: "video-2",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (3/12) | Daniel Vargas",
        path: "video-3",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
    {
        video: v4,
        poster: p4,
        title: "Stray - Dentro del muro (4/12) | Daniel Vargas",
        path: "video-4",
        avatar: avatar,
        name: "Daniel Vargas",
        username: "danielvargas"
    },
]

export default watch;