import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

const ProfileMenuStates = () => {
  const navigate = useNavigate();
  const users = useUsers(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);

  const [home, setHome] = useState(true);
  const [music, setMusic] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [videos, setVideos] = useState(false);
  const [members, setMembers] = useState(false);
  const [store, setStore] = useState(false);
  const [about, setAbout] = useState(false);
  const [loved, setLoved] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleHome = () => {
    setHome(true);
    navigate(`/${userSelected.username}`);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(false);
  };

  const handleMusic = () => {
    setHome(false);
    setMusic(true);
    navigate(`/${userSelected.username}/music`);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(false);
  };

  const handlePhotos = () => {
    setHome(false);
    setMusic(false);
    setPhotos(true);
    navigate(`/${userSelected.username}/photos`);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(false);
  };

  const handleVideos = () => {
    setHome(false);
    setMusic(false);
    setVideos(true);
    navigate(`/${userSelected.username}/videos`);
    setPhotos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(false);
  };

  const handleMembers = () => {
    setHome(false);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(true);
    navigate(`/${userSelected.username}/members`);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(false);
  };

  const handleStore = () => {
    setHome(false);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(true);
    navigate(`/${userSelected.username}/store`);
    setAbout(false);
    setSaved(false);
    setLoved(false);
  };

  const handleAbout = () => {
    setHome(false);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(true);
    navigate(`/${userSelected.username}/about`);
    setSaved(false);
    setLoved(false);
  };

  const handleLoved = () => {
    setHome(false);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(true);
    navigate(`/${userSelected.username}/loved`);
    setSaved(false);
  };

  const handleSaved = () => {
    setHome(false);
    setMusic(false);
    setPhotos(false);
    setVideos(false);
    setMembers(false);
    setStore(false);
    setAbout(false);
    setLoved(false);
    setSaved(true);
    navigate(`/${userSelected.username}/saved`);
  };

  return {
    home,
    music,
    photos,
    videos,
    members,
    store,
    about,
    loved,
    saved,
    setHome,
    setMusic,
    setPhotos,
    setVideos,
    setMembers,
    setStore,
    setAbout,
    setLoved,
    setSaved,
    handleHome,
    handleMusic,
    handlePhotos,
    handleVideos,
    handleMembers,
    handleStore,
    handleAbout,
    handleLoved,
    handleSaved,
  };
};

export default ProfileMenuStates;
