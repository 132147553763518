import { subscribeToPhotos } from "../../firebase/fbFuncions";
import { useEffect, useState } from "react";
import PostPhoto from "./PostPhoto";

function Feed() {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const unsubscribe = subscribeToPhotos((newPhotos) => {
      setPhotos(newPhotos);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      {photos
        .sort((a, b) => b.timestamp - a.timestamp)
        .map((photo, index) => (
          <PostPhoto
            key={index}
            name={photo.user.name}
            picture={photo.user.picture}
            followers={photo.user.followers}
            verified={photo.user.verified}
            username={photo.user.username}
            gallery={photo.photos}
            text={photo.description}
            date={photo.timestamp}
          />
        ))}
    </>
  );
}

export default Feed;
