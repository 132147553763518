function ProfileHome() {
  return (
    <div className="profile-home">
      <div className="block" hidden>
        {/* <div className="">Álbumes y sencillos</div>
        <div className="scroll-x" hidden>
          <div className="music-box"></div>
          <div className="music-box"></div>
          <div className="music-box"></div>
          <div className="music-box"></div>
        </div> */}
      </div>
      <div className="block"></div>
      <div className="block"></div>
      <div className="block"></div>
    </div>
  );
}
export default ProfileHome;
