import { useTranslation } from "react-i18next";

function ProductCard(props) {
  const { t } = useTranslation();

  return (
    <div className="product-card">
      {props.image.length === 0 ? (
        <div className="product-image-load"></div>
      ) : (
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <img
            className="product-image"
            src={props.image}
            alt={props.title}
            loading="lazy"
          />
        </a>
      )}
      <div className="product-details">
        {props.title && <div className="product-title">{props.title}</div>}

        {props.price && (
          <div className="product-price">${props.price.toFixed(2)}</div>
        )}

        {props.link && (
          <a
            className="button-product"
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("button.product.action")}
          </a>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
