import { useParams } from "react-router-dom";
import { useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";

function ProfileStore() {
  const { username } = useParams();
  const users = useUsers(db);
  const userSelected = users.find((user) => user.username === username);

  return (
    <>
      {userSelected ? (
        <div className="profile-store">
          {userSelected.store && (
            <>
              {userSelected.store.map((product, index) => (
                <ProductCard key={index} {...product} />
              ))}
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

export default ProfileStore;
