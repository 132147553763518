import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faLink,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db, storage } from "../../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { v4 } from "uuid";
import { doc, setDoc } from "firebase/firestore";
import ProfileNavMenu from "./ProfileNavMenu";
import { useNavigate } from "react-router-dom";

function ProfileHeader(props) {
  const navigate = useNavigate();
  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);
  const htmlStringDesc = props.description;

  const uploadPicture = async (file) => {
    const userId = userSelected.id;
    const username = userSelected.username;
    console.log("ID del usuario: ", userId, username);

    const storageRef = ref(storage, `users/${username}/picture/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    const userDoc = doc(db, "users", userSelected.id);
    await setDoc(userDoc, { picture: url }, { merge: true });
    return url;
  };

  const [picture, setPicture] = useState(false);
  const [withOutPicture, setWithOutPicture] = useState(false);

  const changePicture = () => {
    setPicture(!picture);
  };

  const removePicture = () => {
    setWithOutPicture(!withOutPicture);
  };

  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await uploadPicture(file);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    uploadPicture(file);
    setPicture(!picture);
  };

  // followers
  // const [followers, SetFollowers] = useState(0);
  const [follow, SetFollow] = useState(false);

  const followed = async (id) => {
    SetFollow(!follow);
    if (follow === false) {
      // SetFollowers(followers + 1);
    } else {
      // SetFollowers(followers - 1);
    }

    // const userRef = collection(db, "users", userSelected.id);

    // try {
    //   await updateDoc(userRef, {
    //     followers: followers,
    //   });
    // } catch (error) {
    //   console.error("Error al actualizar el contador de seguidores: ", error)
    // }
  };

  function abbreviateFollowers(followerCount) {
    if (typeof followerCount !== "number" || isNaN(followerCount)) {
      return "Invalid input";
    }

    const units = ["", " K", " M", " B", " T", " P", " E", " Z", " Y", " GOL"];
    let unitIndex = 0;

    while (followerCount >= 1000 && unitIndex < units.length - 1) {
      followerCount /= 1000;
      unitIndex++;
    }

    return followerCount.toFixed(1).replace(/\.0$/, "") + units[unitIndex];
  }

  const followers = abbreviateFollowers(props.followers);

  const formatWebsite = (url) => {
    return url.replace(/^https?:\/\//, "");
  };

  const goToEditProfile = () => {
    navigate(`/${userSelected.username}/edit`);
  };

  const goToArchive = () => {
    navigate(`/${userSelected.username}/archive`);
  };

  const goToStats = () => {
    navigate(`/${userSelected.username}/stats`);
  };

  return (
    <>
      {userSelected ? (
        <>
          <div className="profile-header">
            <div className="profile-header-details">
              <div className="profile-picture">
                {userSelected.username === props.username ? (
                  <>
                    <button
                      type="button"
                      title={"Cambiar foto de perfil"}
                      onClick={() => changePicture()}
                    >
                      {props.picture ? (
                        <img
                          src={props.picture}
                          alt={"Cambiar foto de perfil"}
                          className="picture"
                          loading="lazy"
                        />
                      ) : (
                        <span className="picture">
                          <FontAwesomeIcon icon={faUser} size="8x" />
                        </span>
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    {props.picture ? (
                      <img
                        src={props.picture}
                        alt={"Cambiar foto de perfil"}
                        className="picture"
                        loading="lazy"
                      />
                    ) : (
                      <span className="picture">
                        <FontAwesomeIcon icon={faUser} size="8x" />
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="profile-data">
                {/* Profile Name */}
                <div className="profile-row">
                  <span className="name">{props.name}</span>
                  {props.verified && (
                    <span className="verified-badge" title="Verificado">
                      <svg
                        aria-label="Verificado"
                        className="x1lliihq x1n2onr6"
                        fill="rgb(0, 149, 246)"
                        height="16"
                        role="img"
                        viewBox="0 0 40 40"
                        width="16"
                      >
                        <title>Verificado</title>
                        <path
                          d="M19.998 3.094 14.638 0l-2.972 5.15H5.432v6.354L0 14.64 3.094 20 0 25.359l5.432 3.137v5.905h5.975L14.638 40l5.36-3.094L25.358 40l3.232-5.6h6.162v-6.01L40 25.359 36.905 20 40 14.641l-5.248-3.03v-6.46h-6.419L25.358 0l-5.36 3.094Zm7.415 11.225 2.254 2.287-11.43 11.5-6.835-6.93 2.244-2.258 4.587 4.581 9.18-9.18Z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  )}
                </div>
                {/* Profile Username & Followers */}
                <div className="profile-row">
                  <span className="username">@{props.username}</span>
                  {followers && (
                    <>
                      <span className="dot">•</span>
                      <div className="followers">
                        <span>{`${followers} Seguidores`}</span>
                      </div>
                    </>
                  )}
                </div>
                {/* Profile Description */}
                <div className="profile-row">
                  <span className="desc" onClick={props.handleAbout}>
                    {props.description ? (
                      <span
                        className="desc-short"
                        dangerouslySetInnerHTML={{ __html: htmlStringDesc }}
                      />
                    ) : (
                      <span className="more">Más Información</span>
                    )}
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      size="lg"
                      title="Ver más"
                    />
                  </span>
                </div>
                {/* Profile Website */}
                {props.website && (
                  <div className="profile-row">
                    <a
                      href={"https://" + formatWebsite(props.website)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="website"
                    >
                      <FontAwesomeIcon icon={faLink} size="xs" />
                      {formatWebsite(props.website)}
                    </a>
                  </div>
                )}
                {/* Buttons */}
                <div className="profile-row buttons">
                  {userSelected ? (
                    <>
                      {userSelected.username === props.username ? (
                        <>
                          <button
                            type="button"
                            onClick={() => goToEditProfile()}
                            className={"button followed-button"}
                          >
                            Editar perfil
                          </button>
                          <button
                            type="button"
                            onClick={() => goToArchive()}
                            className={"button followed-button"}
                          >
                            Archivo
                          </button>
                          {props.show_stats && (
                            <button
                              type="button"
                              onClick={() => goToStats()}
                              className={"button followed-button"}
                            >
                              Estadísticas
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => followed(props.id)}
                            className={
                              !follow
                                ? "button follow-button"
                                : "button followed-button"
                            }
                            id="btnFollow"
                          >
                            {!follow ? "Seguir" : "Siguiendo"}
                          </button>
                          {/* <button
                              type="button"
                              // onClick={() => goToStats()}
                              className={"button followed-button"}
                            >
                              Mensajear
                            </button> */}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="profile-header-nav-menu">
              <ProfileNavMenu
                username={props.username}
                show_music={props.show_music}
                show_members={props.show_members}
                show_store={props.show_store}
              />
            </div>
          </div>
          <div className={!picture ? "menu-picture" : "menu-picture show"}>
            <h3>Cambiar foto del perfil</h3>
            <form onSubmit={handleSubmit}>
              <input type="file" id="pic" onChange={handleFileChange} hidden />
              <button type="button" id="btnUpload">
                <label htmlFor="pic">Subir foto</label>
              </button>
              {props.picture && (
                <button
                  type="reset"
                  id="btnDelete"
                  onClick={() => removePicture()}
                >
                  Eliminar foto actual
                </button>
              )}
              <button type="button" onClick={() => changePicture()}>
                Cancelar
              </button>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ProfileHeader;
