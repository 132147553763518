import note from "../../assets/images/note.svg";

function MusicBox(props) {
  console.log("Props recibidos en MusicBox: ", props);
  return (
    <>
      {props.show_music && (
        <div className="music-box">
          <a
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
            className="music-box-link"
          >
            <div className="music-box-cover">
              <img
                src={props.covers}
                alt={props.title}
                className="cover"
                loading="lazy"
              />
              {props.songs.length > 0 && (
                <div className="music-box-songs">
                  <span className="music-box-songs-icon">
                    <img src={note} alt="Note" />
                  </span>
                  <span className="music-box-songs-text">
                    {props.songs.length > 1 ? (
                      <>{props.songs.length + " Canciones"}</>
                    ) : (
                      <>{props.songs.length + " Canción"}</>
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="music-box-caption">
              <span className="music-box-title">{props.title}</span>
              <span className="music-box-type">
                {props.artists && props.artists.length > 0
                  ? props.artists.map((person, index) => (
                      <span key={index} title={person}>
                        {index !== 0 && ", "}
                        {person}
                      </span>
                    ))
                  : props.name}
                {` • ${props.type}`}
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
}
export default MusicBox;
