import { Link } from "react-router-dom"
import UserCard from "./UserCard";

function WatchCard( props ) {

    // const video = document.getElementById("#video");    

  return (
    <div className="watch-card">
        <div className="watch-card-video">
            <Link to={`/${props.username}/watch/${props.path}`}>
                <video src={props.video} poster={props.poster} ></video>
            </Link>
        </div>
        <div className="watch-card-details">
            <div className="watch-card-details-title">
                <Link className="no-link" to={`/${props.username}/watch/${props.path}`}>{props.title}</Link>
            </div>
            <div className="watch-card-details-user">
                <UserCard 
                    verified={true}
                    name={props.name}
                    avatar={props.avatar}
                    username={props.username}
                />
            </div>
        </div>
    </div>
  )
}

export default WatchCard