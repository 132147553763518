import { useParams } from "react-router-dom";
import { db } from "../../firebase/config";
import { useUsers } from "../../firebase/fbQueries";

function ProfileVideos() {
  const { username } = useParams();
  const users = useUsers(db);
  const userSelected = users.find((user) => user.username === username);

  return (
    <>
      {userSelected ? (
        <div className="profile-videos">
          {userSelected.videos && (
            <>
              {userSelected.videos.map((video, index) => (
                <div key={index} className="video-box">
                  {/* <video src={video} poster={video}></video> */}
                </div>
              ))}
            </>
          )}
        </div>
      ) : null}
    </>
  );
}
export default ProfileVideos;
