import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { auth } from "../../firebase/config";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { website_name } from "../../config/websiteConfig";

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      var user = userCredential.user;
      console.log("Inicio de sesión exitoso", user);

      navigate("/");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error al iniciar sesión", errorCode, errorMessage);
    }
  };

  return (
    <div className="login-page">
      <Helmet>
        <title>Login - {website_name}</title>
      </Helmet>
      <div className="form-box">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form-group">
            <input
              type="email"
              placeholder="Correo electrónico"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
          </div>
          {errors.email && (
            <div className="form-group">
              <span>El correo electrónico es obligatorio*</span>
            </div>
          )}
          <div className="form-group">
            <input
              type="password"
              placeholder="Contraseña"
              {...register("password", { required: true, minLength: 8 })}
            />
          </div>
          {errors.password && (
            <div className="form-group">
              <span>La contraseña es obligatoria*</span>
            </div>
          )}
          <div className="form-group">
            <button type="submit">Acceder</button>
          </div>
          <div className="form-group">
            <Link to={"/register"}>¿No tienes una cuenta?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
