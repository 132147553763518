import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompass,
  faGear,
  faHome,
  faLanguage,
  faMoon,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { auth, db } from "../../firebase/config";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useUsers } from "../../firebase/fbQueries";

import Logo from "./Logo";
// import NavMenu from "./NavMenu";
// import MainMenuStates from "../hooks/MainMenuStates";
import { useState } from "react";

function Header(props) {
  const navigate = useNavigate();

  const users = useUsers(db);
  const authUser = useAuth(db);
  const userSelected = users.find((user) => user.email === authUser.email);

  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
      navigate("/login");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  // const goToProfile = () => {
  //   setHome(false);
  //   setExplore(false);
  //   navigate(`/${userSelected.username}`);
  // };

  // const { home, explore, handleHome, handleExplore } = MainMenuStates();

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="flex">
            <Logo />
          </div>
          <div className="flex">
            {/* <NavMenu
              home={home}
              explore={explore}
              handleHome={handleHome}
              handleExplore={handleExplore}
            /> */}
          </div>
          <div className="flex">
            {!userSelected && (
              <button
                type="button"
                className="button icon-button"
                style={{ marginRight: 14 + "px" }}
                onClick={() => handleSignOut()}
              >
                <FontAwesomeIcon icon={faSignOut} size="sm" />
              </button>
            )}
            {userSelected ? (
              <>
                <button
                  type="button"
                  className="button icon-button picture"
                  onClick={() => handleMenu()}
                  // onClick={handleMenu}
                  aria-label="Ir al perfil"
                  title="Ir al perfil"
                >
                  {userSelected.picture ? (
                    <img src={userSelected.picture} alt={userSelected.name} />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faUser} size="2xs" />
                    </>
                  )}
                </button>
                {/* Menu */}
                <div className={!menu ? "menu" : "menu show"}>
                  <nav className="nav-menu">
                    <ul className="nav-list" onClick={() => handleMenu()}>
                      <li className="nav-item">
                        <Link
                          to={`/${userSelected.username}`}
                          className="nav-link"
                        >
                          <div className="user-box">
                            <div className="picture">
                              {userSelected.picture ? (
                                <>
                                  <img
                                    src={userSelected.picture}
                                    alt={userSelected.name}
                                  />
                                </>
                              ) : (
                                <>
                                  <div className="user-picture">
                                    <FontAwesomeIcon icon={faUser} size="sm" />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="user-data">
                              <div className="name">{userSelected.name}</div>
                              <div className="username">
                                @{userSelected.username}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li className="nav-item">
                        <Link to="/" className="nav-link">
                          <div className="nav-wrapper">
                            <span className="icon">
                              <FontAwesomeIcon icon={faHome} />
                            </span>
                            <span className="text">Home</span>
                          </div>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/explore" className="nav-link">
                          <div className="nav-wrapper">
                            <span className="icon">
                              <FontAwesomeIcon icon={faCompass} />
                            </span>
                            <span className="text">Explorar</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li className="nav-item">
                        <div className="nav-wrapper">
                          <span className="icon">
                            <FontAwesomeIcon icon={faLanguage} />
                          </span>
                          <span className="text">Idioma</span>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="nav-wrapper">
                          <span className="icon">
                            <FontAwesomeIcon icon={faMoon} />
                          </span>
                          <span className="text">Apariencia</span>
                        </div>
                      </li>
                      <li className="nav-item">
                        <div className="nav-wrapper">
                          <span className="icon">
                            <FontAwesomeIcon icon={faGear} />
                          </span>
                          <span className="text">Configuración</span>
                        </div>
                      </li>
                      <li>
                        <hr />
                      </li>
                      <li className="nav-item" onClick={() => handleSignOut()}>
                        <div className="nav-wrapper">
                          <span className="icon">
                            <FontAwesomeIcon icon={faSignOut} size="sm" />
                          </span>
                          <span className="text">Cerrar sesión</span>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
