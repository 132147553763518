import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";

import Feed from "../common/Feed";
import SideBar from "../common/SideBar";

function Home() {
  return (
    <>
      <Helmet>
        <title>{website_name}</title>
      </Helmet>

      <div className="feed">
        <Feed />
      </div>
      
      <aside className="aside">
        <SideBar />
      </aside>
    </>
  )
}

export default Home;