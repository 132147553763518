import Trends from "./Trends";
import Popular from "./Popular";
import AboutApp from "./AboutApp";
import { useTranslation } from "react-i18next";

function SideBar() {
  const { t } = useTranslation();

  const trends = false;

  return (
    <div className="sidebar">
      {trends && (
        <>
          <div className="sidebar-title">{t("title.trends")}</div>
          <div className="sidebar-content">
            <Trends />
          </div>
        </>
      )}
      <div className="sidebar-title">{t("title.popular")}</div>
      <div className="sidebar-content">
        <Popular />
      </div>
      <div className="sidebar-title">{t("title.about")}</div>
      <div className="sidebar-content">
        <AboutApp />
      </div>
    </div>
  );
}

export default SideBar;
